import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import {
  AvtAndName,
  Banner,
  ButtonInvite,
  CountFriend,
  ItemFriend,
  ListBtns,
  ListContainer,
  ListParent,
  ShowList,
  TitleList,
  WrapListContainer,
} from "../Friends/styled";
import { NoWallet, ShowWallet, TitleNoWallet, WalletContainer } from "./styled";
import { shortenAddress } from "../../utils/addressUser";
import { useDispatch } from "../../store/store";
import {
  getDataInfoUser,
  getDataListAsset,
} from "../../store/features/rootSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  convertFixed,
  convertFormatNumber,
} from "../../utils/convertFormatNumber";
import { Coins } from "ton3-core";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import { MBIT_API } from "../../services/api";

export default function WalletPage() {
  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const dispatch = useDispatch();
  const { listAsset, infoUser } = useSelector((state: any) => state.rootStore);

  const [totalBalance, setTotalBalance] = useState<any>(null);

  const handleConnectWallet = () => {
    if (address) {
      tonConnectUI.disconnect();
    } else {
      tonConnectUI.openModal();
    }
  };

  const handleUpdateWalletForUser = async (address: string) => {
    try {
      await MBIT_API.connectWallet(address);
      dispatch(getDataInfoUser());
    } catch (error) {
      console.log("====================================");
      console.log("handleUpdateWalletForUser err", error);
      console.log("====================================");
    }
  };

  useEffect(() => {
    if (address) {
      handleUpdateWalletForUser(address);
      dispatch(getDataListAsset(address));
    }
  }, [address]);

  useEffect(() => {
    if (listAsset && listAsset.length > 0) {
      const tokenShowBalance = listAsset.find(
        (item: any) =>
          item.contract_address === process.env.REACT_APP_TON_ADDRESS
      );
      const balance = convertFixed(
        Number(
          Coins.fromNano(tokenShowBalance.balance, tokenShowBalance?.decimals)
        )
      );
      setTotalBalance({
        balance,
        token: tokenShowBalance,
      });
    }
  }, [listAsset]);

  return (
    <WalletContainer className="animate__animated animate__fadeIn animate__faster">
      {address ? (
        <ShowWallet>
          <Banner>
            <div className="img-wallet row-center">
              <img src="/assets/images/wallet/icon-wallet.png" alt="img" />
            </div>
            <h1>
              {totalBalance
                ? convertFormatNumber(totalBalance?.balance).replaceAll(
                    ".00",
                    ""
                  )
                : 0}{" "}
              {totalBalance ? totalBalance?.token?.symbol : ""}
            </h1>
            <p>Total Balance</p>
          </Banner>
          <ListContainer className="bg-gray">
            <WrapListContainer>
              <TitleList className="row-between">
                <h1>My Wallet Asset</h1>
                <CopyToClipboard
                  text={address}
                  onCopy={() => {
                    toast.success("Copied !!");
                  }}
                >
                  <CountFriend className="row-center bg-gray">
                    <div className="img-user">
                      <img
                        src="/assets/images/friends/icon-user.png"
                        alt="user"
                      />
                    </div>
                    <p>{shortenAddress(address)}</p>
                  </CountFriend>
                </CopyToClipboard>
              </TitleList>
              <ListParent>
                <ShowList>
                  {listAsset?.map((item: any, index: number) => (
                    <ItemFriend key={index} className="row-between">
                      <AvtAndName className="row-left">
                        <div className="img-avt row-center img-token">
                          <img src={item?.image} alt="ton" />
                        </div>
                        <p>{item?.symbol}</p>
                      </AvtAndName>
                      <AvtAndName
                        className="row-right"
                        style={{
                          padding: "12px 24px",
                          width: "auto",
                        }}
                      >
                        <div className="img-avt row-center img-coin">
                          <img
                            src="/assets/images/header/img-yl.png"
                            alt="img"
                          />
                        </div>
                        <p>
                          {convertFixed(
                            Number(Coins.fromNano(item.balance, item?.decimals))
                          )}
                        </p>
                      </AvtAndName>
                    </ItemFriend>
                  ))}
                </ShowList>
              </ListParent>
            </WrapListContainer>
            <ListBtns className="row-center">
              <ButtonInvite
                className="row-center"
                onClick={handleConnectWallet}
              >
                <div className="img-btn row-center">
                  <img
                    src="/assets/images/wallet/icon-wallet-sm.png"
                    alt="btn"
                  />
                </div>
                <p>Disconnect Wallet</p>
              </ButtonInvite>
            </ListBtns>
          </ListContainer>
        </ShowWallet>
      ) : (
        <NoWallet>
          <TitleNoWallet className="row-center">
            <div className="img-no-wallet row-center">
              <img
                src="/assets/images/wallet/img-no-wallet.png"
                alt="no-wallet"
              />
            </div>
            <h1>Connect Your Wallet</h1>
            <p>
              Open Wallet in Telegram or select your <br /> wallet to connect
            </p>
          </TitleNoWallet>
          <ListBtns className="row-center">
            <ButtonInvite className="row-center" onClick={handleConnectWallet}>
              <div className="img-btn row-center">
                <img src="/assets/images/wallet/icon-wallet-sm.png" alt="btn" />
              </div>
              <p>Connect Wallet</p>
            </ButtonInvite>
          </ListBtns>
        </NoWallet>
      )}
    </WalletContainer>
  );
}
