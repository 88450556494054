import styled from "styled-components";

export const AppContainer = styled.div<{ bgUrl?: any }>`
  position: relative;
  min-height: 100svh;
  color: #fff;
  z-index: 1;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 101;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.26s ease;
  }
  &::before {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100svh;
    pointer-events: none;
    content: "";
    background: url(${({ bgUrl }) => (bgUrl ? bgUrl : "/static/bg-main.png")});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -1;
  }
  &.show-overlay {
    &:after {
      opacity: 1;
    }
  }
`;
