import { useNavigate } from "react-router-dom";
import {
  DashboardContainer,
  DashboardMain,
} from "../../Layout/Dashboard/styled";
import { ButtonInvite, ListBtns } from "../Friends/styled";
import {
  RewardPageContainer,
  StatusReward,
  StepReward,
  StepsList,
  TitleCenter,
  WrapTitleCenter,
} from "./styled";
import { ROUTERS } from "../../constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MBIT_API } from "../../services/api";

export default function RewardPage() {
  const POINT_REWARD_SETTINGS = {
    oneYear: 1000,
    moreYear: 1500,
    isPremium: 500,
  };

  const { infoUser } = useSelector((state: any) => state.rootStore);
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [pointReward, setPointReward] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleNextStep = async (index: number) => {
    if (isLoading) return;
    if (index === 1) {
      setStep(index);
    } else {
      setIsLoading(true);
      await handleVerifyAccount();
      setIsLoading(false);
      navigate(ROUTERS.earn);
    }
  };

  const onSetPointReward = (ages: number, isPremium: boolean) => {
    const rewardByAge =
      ages === 1
        ? POINT_REWARD_SETTINGS.oneYear
        : POINT_REWARD_SETTINGS.oneYear +
          (ages - 1) * POINT_REWARD_SETTINGS.moreYear;

    const rewardByIsPremium = isPremium ? POINT_REWARD_SETTINGS.isPremium : 0;
    setPointReward(rewardByAge + rewardByIsPremium);
  };

  const handleVerifyAccount = async () => {
    try {
      await MBIT_API.verifyAccount();
    } catch (error) {
      console.log("====================================");
      console.log("handleVerifyAccount err", error);
      console.log("====================================");
    }
  };

  useEffect(() => {
    if (infoUser) {
      const { account_age, is_premium } = infoUser;
      onSetPointReward(account_age, is_premium);
    }
  }, [infoUser]);

  useEffect(() => {
    setTimeout(() => {
      handleNextStep(1);
    }, 8000);
    setTimeout(() => {
      handleNextStep(2);
    }, 2000);
  }, []);

  return (
    <DashboardContainer className="animate__animated animate__fadeIn animate__faster">
      <DashboardMain>
        <WrapTitleCenter>
          <div className="img-rocket row-center">
            <img src="/assets/images/header/icon-rocket.png" alt="" />
          </div>
          <div className="img-like row-center">
            <img src="/assets/images/friends/img-like.png" alt="" />
          </div>
          <div className="img-cake row-center">
            <img src="/assets/images/common/icon-cake.png" alt="" />
          </div>
          <div className="img-heart row-center">
            <img src="/assets/images/common/icon-heart.png" alt="" />
          </div>
          <h1>
            {step === 0
              ? `Your account ID is #${infoUser?.telegram_id}`
              : `Rewarded 🚀`}
          </h1>
          <TitleCenter className="row-center">
            <h2
              style={{
                fontSize: step === 0 ? "80px" : "60px",
              }}
            >
              {step === 0 ? `${infoUser?.account_age}` : `${pointReward}`}
            </h2>
            <p>{step === 0 ? "Years ago" : "MBIT"}</p>
          </TitleCenter>
        </WrapTitleCenter>
        <StatusReward className="row-center">
          <div className="row-center title-img">
            <div className="img-status row-center">
              {step === 0 ? (
                <img src="/assets/images/common/icon-smile.png" alt="icon" />
              ) : (
                <img
                  src="/assets/images/common/icon-smile-grass.png"
                  alt="icon"
                />
              )}
            </div>
            <h2>{step === 0 ? "Legendary Status!" : "We owe you MBIT"}</h2>
          </div>
          <p>{step === 0 ? "You've joined Telegram" : "Here you gooooo"}</p>
        </StatusReward>
        <StepsList className="row-center">
          {[1, 2].map((item: any, index: number) => (
            <StepReward className={index === step ? "active" : ""}></StepReward>
          ))}
        </StepsList>
        <RewardPageContainer>
          <ListBtns
            className="row-center"
            style={{
              marginTop: step === 0 ? "30px" : "55px",
            }}
          >
            <ButtonInvite
              className="row-center"
              onClick={() => {
                handleNextStep(step + 1);
              }}
            >
              <div className="img-btn row-center">
                {step === 0 ? (
                  <img src="/assets/images/common/icon-next.png" alt="btn" />
                ) : (
                  <img src="/assets/images/common/icon-flag.png" alt="btn" />
                )}
              </div>
              <p>{step === 0 ? "Continue" : "Finish"}</p>
            </ButtonInvite>
          </ListBtns>
        </RewardPageContainer>
      </DashboardMain>
    </DashboardContainer>
  );
}
