import styled from "styled-components";
import {
  AvtAndName,
  Banner,
  ButtonInvite,
  ItemFriend,
  ListBtns,
  ListParent,
} from "../Friends/styled";

export const WalletContainer = styled.div`
  position: relative;
  ${Banner} {
    .img-wallet {
      position: absolute;
      right: 0;
      top: 2px;
      max-width: 200px;
    }
  }
  ${ItemFriend} {
    padding-left: 16px;
  }

  ${AvtAndName} {
    .img-token {
      img {
        border-radius: 50%;
      }
    }
    .img-coin {
      width: 30px;
    }
  }

  ${ListParent} {
    height: calc(100vh - 450px);
    height: calc(100svh - 450px);
  }

  ${ButtonInvite} {
    width: 100%;
  }
`;

export const ShowWallet = styled.div`
  position: relative;
  ${ListBtns} {
    padding: 0 15px;
  }
`;

export const NoWallet = styled.div`
  height: calc(100svh - 185px);
  height: calc(100vh - 185px);
  position: relative;
  padding-top: 100px;

  ${ButtonInvite} {
    width: 100%;
    border-radius: 20px;
    background: linear-gradient(180deg, #48c8ff 0%, #0068ef 100%),
      linear-gradient(180deg, #b5abd7 0%, #2b2b2b 100%);
    box-shadow: 0px 5.235px 10.034px 0px rgba(255, 255, 255, 0.4) inset,
      0px -5.235px 10.034px 0px rgba(255, 255, 255, 0.4) inset;
  }
  ${ListBtns} {
    position: absolute;
    bottom: 15px;
  }
`;

export const TitleNoWallet = styled.div`
  flex-direction: column;
  .img-no-wallet {
    max-width: 185px;
  }
  h1 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 21px; /* 105% */
  }
  p {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
`;
