import { useSelector } from "react-redux";
import { HeaderContainer, HeaderWrap, InfoPoints, InfoUser } from "./styled";
import { useEffect, useState } from "react";
import { convertBigNumberToText } from "../../utils/convertBigNumber";
import { convertFormatNumber } from "../../utils/convertFormatNumber";

export default function Header() {
  const { infoUser, currentPoint } = useSelector(
    (state: any) => state.rootStore
  );

  const [point, setPoint] = useState(currentPoint);

  useEffect(() => {
    setPoint(currentPoint);
  }, [currentPoint]);

  useEffect(() => {
    if (infoUser) {
      setPoint(infoUser?.points);
    }
  }, [infoUser]);

  return (
    <HeaderContainer>
      <HeaderWrap>
        <InfoUser className="bg-dark">
          <div className="img-avt">
            <img src="/assets/images/header/img-avt.png" alt="avt" />
          </div>
          <h1>{infoUser?.fullname}</h1>
        </InfoUser>
        <InfoUser className="bg-dark">
          <InfoPoints>
            <div className="img-yl">
              <img src="/assets/images/header/img-yl.png" alt="img" />
            </div>
            <h1>
              {point
                ? point > 1000000
                  ? convertBigNumberToText(Number(point))
                      ?.toString()
                      .replaceAll(".00", "")
                  : convertFormatNumber(Number(point))?.replaceAll(".00", "")
                : "--.--"}
            </h1>
          </InfoPoints>
        </InfoUser>
      </HeaderWrap>
    </HeaderContainer>
  );
}
