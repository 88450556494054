import styled from "styled-components";
import { NameTask, ProcessTask } from "../Tasks/styled";
import { ButtonInvite, ListBtns } from "../Friends/styled";

export const RewardPageContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: -20px;
  ${ButtonInvite} {
    width: 100%;
    border-radius: 20px;
    background: linear-gradient(180deg, #48c8ff 0%, #0068ef 100%),
      linear-gradient(180deg, #b5abd7 0%, #2b2b2b 100%);
    box-shadow: 0px 5.235px 10.034px 0px rgba(255, 255, 255, 0.4) inset,
      0px -5.235px 10.034px 0px rgba(255, 255, 255, 0.4) inset;
  }
`;

export const WrapTitleCenter = styled.div`
  margin-top: 80px;
  margin-bottom: 70px;
  h1 {
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 143.75% */
    background: -webkit-linear-gradient(180deg, #01c3ff, #9f66ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 200px;
    margin: auto;
    margin-bottom: 20px;
  }
  .img-rocket {
    max-width: 105px;
    position: absolute;
    top: 50px;
    left: 50px;
    animation: upAndDown 2s infinite linear;
  }
  .img-like {
    max-width: 80px;
    position: absolute;
    top: 200px;
    right: 20px;
    animation: upAndDown 3s infinite linear;
  }

  .img-cake {
    max-width: 60px;
    position: absolute;
    bottom: 450px;
    left: 0px;
    animation: upAndDown 4s infinite linear;
  }

  .img-heart {
    max-width: 80px;
    position: absolute;
    bottom: 300px;
    right: 60px;
    animation: upAndDown 2s infinite linear;
  }
`;

export const TitleCenter = styled.div`
  width: 220px;
  height: 220px;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  flex-direction: column;

  h2 {
    color: #fff;
    font-family: Inter;
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
    line-height: 72px; /* 90% */
  }
  p {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 87.5% */
  }
`;

export const StatusReward = styled.div`
  flex-direction: column;
  gap: 8px;
  margin-bottom: 45px;
  .img-status {
    max-width: 35px;
    margin-right: 5px;
    animation: rotateAround 3s infinite linear;
  }
  h2 {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 21px; /* 87.5% */
  }
  p {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px; /* 78.571% */
  }
`;

export const StepsList = styled.div`
  gap: 21px;
  .active {
    background: linear-gradient(180deg, #6a48ff 0%, #0068ef 100%),
      linear-gradient(180deg, #674dff 0%, #29136f 78.35%),
      linear-gradient(180deg, #4d45bd 0%, #12133e 100%);
    mix-blend-mode: plus-lighter;
    box-shadow: 0px 3.714px 7.118px 0px rgba(255, 255, 255, 0.3) inset,
      0px -3.714px 7.118px 0px rgba(255, 255, 255, 0.5) inset;
  }
`;

export const StepReward = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border-radius: 8.584px;
  border: 0.709px solid #fff;
  mix-blend-mode: plus-lighter;
  box-shadow: 0px 3.714px 7.118px 0px rgba(255, 255, 255, 0.3) inset,
    0px -3.714px 7.118px 0px rgba(255, 255, 255, 0.5) inset;
`;
