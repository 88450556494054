import { useEffect, useState } from "react";
import {
  Banner,
  ListContainer,
  ListParent,
  ShowList,
  TitleList,
  WrapListContainer,
} from "../Friends/styled";
import {
  ClaimReward,
  ItemTask,
  ItemType,
  NameTask,
  ProcessTask,
  Reward,
  SelectType,
  TaskContainer,
} from "./styled";
import { useSelector } from "react-redux";
import { convertFormatNumber } from "../../utils/convertFormatNumber";
import { MBIT_API } from "../../services/api";
import { useDispatch } from "../../store/store";
import {
  getDataInfoUser,
  getDataListTask,
  setDataPoint,
} from "../../store/features/rootSlice";
import WebApp from "@twa-dev/sdk";

export default function TaskPage() {
  const TaskStatus = {
    PROGRESSING: "PROCESSING",
    OPEN: "OPEN",
    CLAIM: "CLAIM",
    COMPLETED: "COMPLETED",
  };

  const TYPE_TASK = [
    {
      label: "Mission",
      type: "daily",
    },
    {
      label: "Partner Quests",
      type: "mission",
    },
  ];

  const { listTask, infoUser } = useSelector((state: any) => state.rootStore);
  const dispatch = useDispatch();

  const [indexIsLoading, setIndexIsLoading] = useState(-1);
  const [typeTask, setTypeTask] = useState("daily");
  const [isMission, setIsMission] = useState(true);

  const handleOpenNewWindow = async (task: any) => {
    try {
      WebApp.openLink(task.task.description);
      dispatch(getDataListTask());
    } catch (error) {
      console.log("====================================");
      console.log("handleOpenNewWindow err", error);
      console.log("====================================");
    }
  };

  const handleExcuteTask = async (task: any, index: number) => {
    try {
      setIndexIsLoading(index);
      await MBIT_API.excuteTask({ task_id: task.task_id });
      setTimeout(
        () => {
          dispatch(getDataListTask());
          if (task.status === TaskStatus.CLAIM) {
            dispatch(getDataInfoUser());
            dispatch(setDataPoint(infoUser?.points + task.points));
          } else if (task.status === TaskStatus.OPEN) {
            handleOpenNewWindow(task);
          }
          setIndexIsLoading(-1);
        },
        task.status === TaskStatus.OPEN
          ? 500
          : task.status !== TaskStatus.CLAIM
          ? 4000
          : 2000
      );
    } catch (error) {
      console.log("====================================");
      console.log("handleExcuteTask err", error);
      console.log("====================================");
      setTimeout(
        () => {
          setIndexIsLoading(-1);
        },
        task.status === TaskStatus.OPEN
          ? 500
          : task.status !== TaskStatus.CLAIM
          ? 4000
          : 2000
      );
    }
  };

  const handleSelectType = (type: string) => {
    setTypeTask(type);
    setIsMission(type === "daily");
  };

  return (
    <TaskContainer className="animate__animated animate__fadeIn animate__faster">
      <Banner>
        <div className="img-cake">
          <img src="/assets/images/task/img-cake.png" alt="img" />
        </div>
        <h1>Today's Mission</h1>
        <p>Perform missions to receive MBIT</p>
      </Banner>
      <ListContainer className="bg-gray">
        <WrapListContainer>
          {/* <TitleList className="row-between">
            <h1>Mission List</h1>
          </TitleList> */}
          <SelectType className="row-center">
            {TYPE_TASK.map((item: any, index: number) => (
              <ItemType
                key={index}
                className={typeTask === item.type ? "active" : ""}
                onClick={() => {
                  handleSelectType(item.type);
                }}
              >
                <p>{item.label}</p>
              </ItemType>
            ))}
          </SelectType>
          <ListParent>
            <ShowList>
              {listTask &&
                listTask.data
                  ?.filter((jtem: any) => jtem?.task?.is_mission === isMission)
                  .map(
                    (item: any, index: number) =>
                      item?.task.image && (
                        <ItemTask
                          key={index}
                          className="row-between"
                          onClick={() => {
                            indexIsLoading !== index &&
                              handleExcuteTask(item, index);
                          }}
                        >
                          <NameTask className="row-center">
                            <div className="img-task row-center">
                              <img src={item.task.image} alt="task" />
                            </div>
                            <div>
                              <h1>{item?.task?.name}</h1>
                              <ProcessTask
                                className="row-center"
                                percent={
                                  item?.status !== "COMPLETED" ? "0" : "100%"
                                }
                              >
                                <p>
                                  {item?.status !== "COMPLETED" ? "0" : "1"}/1
                                </p>
                              </ProcessTask>
                            </div>
                          </NameTask>
                          <Reward className="row-center">
                            <div className="img-coin row-center">
                              <img
                                src="/assets/images/header/img-yl.png"
                                alt="img"
                              />
                            </div>
                            <p>
                              +
                              {convertFormatNumber(
                                item?.task?.point
                              ).replaceAll(".00", "")}
                              MBIT
                            </p>
                          </Reward>
                          {item?.status !== "COMPLETED" ? (
                            <ClaimReward
                              className={
                                item?.status === "OPEN"
                                  ? "row-left"
                                  : item?.status === "CLAIM"
                                  ? "row-left claim"
                                  : item?.status === "PROCESSING"
                                  ? "row-left claim"
                                  : "row-left claimed"
                              }
                            >
                              <div className="img-reward row-center">
                                {indexIsLoading === index ? (
                                  <img
                                    className="rotage-img"
                                    src="/assets/images/task/img-spin.png"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src="/assets/images/task/img-go.png"
                                    alt="img"
                                  />
                                )}
                              </div>
                              <p>
                                {item?.status === "OPEN"
                                  ? "Go"
                                  : item?.status === "CLAIM"
                                  ? "Claim"
                                  : item?.status === "PROCESSING"
                                  ? "Check"
                                  : ""}
                              </p>
                            </ClaimReward>
                          ) : (
                            <div className="img-claimed row-center">
                              <img
                                src="/assets/images/task/img-claimed.png"
                                alt=""
                              />
                            </div>
                          )}
                        </ItemTask>
                      )
                  )}
            </ShowList>
          </ListParent>
        </WrapListContainer>
      </ListContainer>
    </TaskContainer>
  );
}
