import styled from "styled-components";
import {
  AvtAndName,
  Banner,
  ListParent,
  WrapListContainer,
} from "../Friends/styled";

export const TopContainer = styled.div`
  position: relative;
  ${Banner} {
    align-items: center;
    overflow: hidden;
    h1 {
      text-align: center;
      font-size: 20px;
    }
    .img-cake {
      max-width: 85px;
      position: absolute;
      right: 0;
      top: -15px;
    }
  }
  ${ListParent} {
    height: calc(100svh - 445px);
  }

  ${WrapListContainer} {
    padding-bottom: 0;
    position: relative;
  }

  ${AvtAndName} {
    &:nth-child(2) {
      padding: 6px 12px;
    }
    p {
      font-size: 14px;
    }
  }

  .bg-gray {
    padding-bottom: 0;
  }

  h2 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
  }
  h3 {
    color: #aeaeae;
    text-align: left;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
  h4 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    margin: 0 6px;
  }
`;

export const RowBanner = styled.div`
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.25);
  padding: 0px 18px;
  padding-right: 6px;
  height: 51px;
  width: 100%;
  margin-top: 10px;
  .img-cup {
    max-width: 52px;
    margin-top: -40px;
    margin-right: 5px;
  }
  .img-coin {
    max-width: 25px;
    margin-right: 5px;
  }
  .bg-dark {
    padding: 6px 15px;
  }
`;

export const RowUserTop = styled.div`
  width: 100%;
  gap: 8px;
`;

export const UserTop = styled.div`
  display: flex;
  padding: 8px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.15);
  width: calc((100% - 8px) / 2);
  .img-rank {
    max-width: 35px;
    margin-right: 5px;
  }
  .img-coin {
    max-width: 20px;
    right: 5px;
  }
  .bg-dark {
    padding: 6px 15px;
  }
`;

export const FooterLine = styled.div`
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
  height: 100px;
  background: linear-gradient(180deg, rgba(50, 48, 46, 0) 61.97%, #32302e 100%);
`;
