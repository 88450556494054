import styled from "styled-components";

export const FriendsContainer = styled.div`
  position: relative;
`;

export const Banner = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  min-height: 110px;
  gap: 8px;
  border-radius: 20px;
  background: linear-gradient(275deg, #01c3ff 18.6%, #9f66ff 97.42%);
  .img-heart {
    max-width: 75px;
    position: absolute;
    top: 0;
    left: 45%;
  }
  .img-like {
    max-width: 75px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  h1 {
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 21px; /* 105% */
  }
  p {
    color: rgba(255, 255, 255, 0.75);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    text-align: left;
    max-width: 220px;
  }
`;

export const InfoReward = styled.div`
  margin-top: 6px;
  width: 100%;
  gap: 8px;
  .bg-dark {
    width: calc(100% / 2);
  }
  .img-member {
    max-width: 40px;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
`;

export const TypeInvite = styled.div`
  h1 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
    margin-bottom: 3px;
    margin-right: 3px;
  }
`;

export const ListContainer = styled.div`
  position: relative;
  margin-top: 10px;
`;

export const WrapListContainer = styled.div`
  padding: 10px;
`;

export const TitleList = styled.div`
  width: 100%;
  h1 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 80% */
  }
  .img-user {
    max-width: 20px;
    margin-right: 5px;
  }
`;

export const CountFriend = styled.div`
  min-width: 130px;
`;

export const ListParent = styled.div`
  position: relative;
  overflow-y: scroll;
  margin-top: 10px;
  height: calc(100vh - 520px);
  height: calc(100svh - 520px);
  width: 100%;
`;

export const ShowList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const ItemFriend = styled.div`
  width: 100%;
  border-radius: 38px;
  background: rgba(0, 0, 0, 0.25);
  padding-left: 6px;
  .img-avt {
    max-width: 33px;
    margin-right: 5px;
  }
`;

export const AvtAndName = styled.div`
  position: relative;
  &:nth-child(2) {
    background: rgba(0, 0, 0, 0.15);
    border-top-right-radius: 38px;
    border-bottom-right-radius: 38px;
    padding: 6px 24px;
    width: 130px;
  }
`;

export const ListBtns = styled.div`
  position: relative;
  width: 100%;
`;

export const ButtonInvite = styled.div`
  width: calc((100% - 8px) / 2);
  display: flex;
  padding: 12px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 20px;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #b5abd7 0%, #2b2b2b 100%),
    linear-gradient(180deg, #9779f8 0%, #4800ef 100%);
  box-shadow: 0px 5.235px 10.034px 0px rgba(255, 255, 255, 0.4) inset,
    0px -5.235px 10.034px 0px rgba(255, 255, 255, 0.4) inset;
  &:nth-child(2) {
    border-radius: 20px;
    background: linear-gradient(180deg, #48c8ff 0%, #0068ef 100%),
      linear-gradient(180deg, #b5abd7 0%, #2b2b2b 100%);
    box-shadow: 0px 5.235px 10.034px 0px rgba(255, 255, 255, 0.4) inset,
      0px -5.235px 10.034px 0px rgba(255, 255, 255, 0.4) inset;
  }
  .img-btn {
    max-width: 20px;
    margin-right: 5px;
  }
`;
