import styled from "styled-components";

export const FooterContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 16px;
  left: 0;
  z-index: 99;
  padding: 0 16px;
  @media screen and (max-width: 400px) {
    padding: 0;
  }
`;

export const NavItem = styled.div`
  max-width: 70.908px;
  width: calc((100vw - 32px) / 5);
  height: 70.908px;
  flex-shrink: 0;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    margin-top: -10px;
    margin-bottom: 5px;
    transition: all.3s;
  }
  .img-nav {
    max-width: 52px;
  }
`;

export const FooterWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.15);
  .active {
    ${NavItem} {
      transition: all.3s;
      border-radius: 20px;
      background: linear-gradient(180deg, #6a48ff 0%, #0068ef 100%);
      mix-blend-mode: plus-lighter;
      box-shadow: 0px 5.235px 10.034px 0px rgb(255, 255, 255, 0.3) inset,
        0px -5.235px 10.034px 0px rgb(255, 255, 255, 0.3) inset;
    }
  }
`;
