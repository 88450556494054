import { useNavigate } from "react-router-dom";
import {
  DashboardContainer,
  DashboardMain,
} from "../../Layout/Dashboard/styled";
import { ButtonInvite, ListBtns } from "../Friends/styled";
import { NameTask, ProcessTask } from "../Tasks/styled";
import {
  ItemStatus,
  ListStatus,
  TitleVerify,
  VerifyAccountContainer,
} from "./styled";
import { ROUTERS } from "../../constants";
import { useEffect, useState } from "react";

export default function VerifyAccountPage() {
  const navigate = useNavigate();
  const [percentVerifyAge, setPercentVerifyAge] = useState<any>(0);
  const [percentVerifyLevel, setPercentVerifyLevel] = useState(0);
  const [percentVerifyOG, setPercentVerifyOG] = useState(0);
  const [percentVerifyPremium, setPercentVerifyPremium] = useState(0);

  const handleContinue = () => {
    if (
      percentVerifyAge === 100 &&
      percentVerifyLevel === 100 &&
      percentVerifyOG === 100 &&
      percentVerifyPremium === 100
    )
      navigate(ROUTERS.reward);
  };

  useEffect(() => {
    const intervalSetPecent = setInterval(() => {
      const percentRandomAge = Math.floor(Math.random() * 45);
      const percentRandomLevel = Math.floor(Math.random() * 50);
      const percentRandomOG = Math.floor(Math.random() * 65);
      const percentRandomPre = Math.floor(Math.random() * 55);
      setPercentVerifyAge((prev: any) =>
        Math.min(prev + percentRandomAge, 100)
      );
      setPercentVerifyLevel((prev: any) =>
        Math.min(prev + percentRandomLevel, 100)
      );
      setPercentVerifyOG((prev: any) => Math.min(prev + percentRandomOG, 100));
      setPercentVerifyPremium((prev: any) =>
        Math.min(prev + percentRandomPre, 100)
      );
    }, 200);
    return () => {
      clearInterval(intervalSetPecent);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      navigate(ROUTERS.reward);
    }, 8000);
  }, []);

  return (
    <DashboardContainer className="animate__animated animate__fadeIn animate__faster">
      <DashboardMain>
        <VerifyAccountContainer>
          <TitleVerify>
            <div className="img-verify">
              <img src="/assets/images/common/img-verify.png" alt="verify" />
            </div>
            <h1>
              MBIT - Best Crypto <br /> wallet for you
            </h1>
          </TitleVerify>
          <ListStatus>
            {STATUS_LIST.map((item: any, index: number) => (
              <ItemStatus>
                <NameTask className="row-center">
                  <div className="img-task row-center">
                    <img src="/assets/images/task/img-task.png" alt="task" />
                  </div>
                  <div>
                    <h1>{item.label}</h1>
                    <ProcessTask
                      className="row-center"
                      percent={
                        index === 0
                          ? `${percentVerifyAge}%`
                          : index === 1
                          ? `${percentVerifyLevel}%`
                          : index === 2
                          ? `${percentVerifyPremium}%`
                          : `${percentVerifyOG}%`
                      }
                    ></ProcessTask>
                  </div>
                </NameTask>
                <div className="img-check row-center">
                  {index === 0 && percentVerifyAge === 100 && (
                    <img
                      src="/assets/images/common/icon-check.png"
                      alt="check"
                    />
                  )}
                  {index === 1 && percentVerifyLevel === 100 && (
                    <img
                      src="/assets/images/common/icon-check.png"
                      alt="check"
                    />
                  )}
                  {index === 2 && percentVerifyPremium === 100 && (
                    <img
                      src="/assets/images/common/icon-check.png"
                      alt="check"
                    />
                  )}
                  {index === 3 && percentVerifyOG === 100 && (
                    <img
                      src="/assets/images/common/icon-check.png"
                      alt="check"
                    />
                  )}
                </div>
              </ItemStatus>
            ))}
          </ListStatus>
          <ListBtns className="row-center">
            <ButtonInvite className="row-center" onClick={handleContinue}>
              <div className="img-btn row-center">
                <img src="/assets/images/common/icon-next.png" alt="btn" />
              </div>
              <p>Continue</p>
            </ButtonInvite>
          </ListBtns>
        </VerifyAccountContainer>
      </DashboardMain>
    </DashboardContainer>
  );
}

const STATUS_LIST = [
  {
    label: "Account Age Verified",
    status: true,
    percent: "100%",
  },
  {
    label: "Activity Level Analyzed",
    status: true,
    percent: "100%",
  },
  {
    label: "Telegram Premium Checked",
    status: false,
    percent: "90%",
  },
  {
    label: "OG Status Confirmed",
    status: false,
    percent: "70%",
  },
];
