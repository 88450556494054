import { useEffect, useState } from "react";
import {
  CountClick,
  EarnContainer,
  EarnLevel,
  EarnRowLevel,
  PointAppear,
  TimeCoundown,
  WrapClick,
} from "./styled";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { MBIT_API } from "../../services/api";
import { SendClickCountType } from "../../services/api/apiType";
import { useDispatch } from "../../store/store";
import { getDataInfoUser, setDataPoint } from "../../store/features/rootSlice";
import dayjs from "dayjs";
import WebApp from "@twa-dev/sdk";

export default function EarnPage() {
  const SETTINGS = {
    MaxClickcount: 200,
    timeToReset: 10, // hours
  };

  const [pointAppearArray, setPointAppearArray] = useState<any>([{}]);
  const { infoUser } = useSelector((state: any) => state.rootStore);
  const dispatch = useDispatch();

  const [clickCount, setClickCount] = useState(0);
  const [currentPoint, setCurrentPoint] = useState(infoUser?.points);
  const [maxClickCount, setMaxClickCount] = useState(infoUser?.max_click_count);
  const [currentExp, setCurrentExp] = useState(infoUser?.exp);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSecond] = useState(0);

  const countDownTime = (timeLimit: string) => {
    const countDownDate = new Date(timeLimit).getTime();
    // Update the count down every 1 second
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      // Time calculations for days, hours, minutes and seconds
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      );
      setSecond(
        Math.floor((distance % (1000 * 60)) / 1000) <= 0
          ? 0
          : Math.floor((distance % (1000 * 60)) / 1000)
      );

      if (distance <= 0) {
        clearInterval(x);
      }
    }, 1000);
  };

  const handleClickCoin = async (event: any) => {
    if (maxClickCount === 0) return;
    WebApp.HapticFeedback.impactOccurred("light");
    // caculate click to send to serve
    setMaxClickCount(maxClickCount - 1);
    setCurrentExp(currentExp + 1);
    setClickCount(clickCount + 1);
    setCurrentPoint(currentPoint + 1);
    if (currentExp + 1 > infoUser?.max_exp) {
      handleUpLevel(currentExp + 1);
    }
    // set point appear
    const newCoinArray: any = [
      ...pointAppearArray,
      { id: Date.now(), text: 1 },
    ];
    if (event.touches) {
      for (let i = 0; i < event.touches.length; i++) {
        const touch = event.touches[i];
        const x = touch.clientX;
        const y = touch.clientY - 150;
        newCoinArray.push({
          text: 1,
          x,
          y,
        });
      }
    } else {
      const x = event.clientX;
      const y = event.clientY - 150;
      newCoinArray.push({
        id: Date.now(),
        text: 1,
        x,
        y,
      });
    }
    setPointAppearArray(newCoinArray);
    debouncedSendDataToServer();
  };

  const handleUpLevel = async (exp: number) => {
    setCurrentExp(0);
    await handleSendDataToServer({
      click_count: clickCount,
    });
    setClickCount(0);
    await dispatch(getDataInfoUser());
  };

  const handleSendDataToServer = async (payload: SendClickCountType) => {
    try {
      await MBIT_API.sendClickCount(payload);
      dispatch(getDataInfoUser());
    } catch (error) {
      console.log("====================================");
      console.log("handleSendDataToServer err", error);
      console.log("====================================");
    }
  };

  const debouncedSendDataToServer = useDebouncedCallback(
    async () => {
      handleSendDataToServer({
        click_count: clickCount,
      });
      setClickCount(0);
    },
    // delay in ms
    1500
  );

  useEffect(() => {
    const intervalPointAppearArray = setInterval(() => {
      const newArr = [...pointAppearArray];
      newArr.pop();
      setPointAppearArray(newArr);
    }, 4000);

    return () => {
      clearInterval(intervalPointAppearArray);
    };
  }, []);

  useEffect(() => {
    dispatch(setDataPoint(currentPoint));
  }, [currentPoint]);

  useEffect(() => {
    if (infoUser) {
      const timeCountDown: any = infoUser.time_reset_click_count
        ? dayjs(infoUser.time_reset_click_count)
        : dayjs().add(10, "hours");

      countDownTime(timeCountDown);
    }
  }, [infoUser]);

  return (
    <EarnContainer className="animate__animated animate__fadeIn animate__faster">
      <EarnRowLevel className="bg-gray row-between">
        <CountClick className="row-left">
          <div className="img-lightning">
            <img src="/assets/images/earn/icon-lightning.png" alt="icon" />
          </div>
          <p>
            {maxClickCount}/{SETTINGS.MaxClickcount}
          </p>
        </CountClick>
        <CountClick className="bg-dark row-right">
          <div className="img-boost bg-light">
            <img src="/assets/images/earn/icon-boost.png" alt="icon" />
          </div>
          <p>Soon....</p>
        </CountClick>
        <EarnLevel percent={(currentExp / infoUser?.max_exp) * 100}>
          <div className="around-2"></div>
          <div className="around-3">
            <h1>Lv. {infoUser?.level < 10 ? `0${infoUser?.level}` : "01"}</h1>
            <div className="bg-dark row-center">
              <div className="img-star">
                <img src="/assets/images/earn/img-star.png" alt="star" />
              </div>
              <p>
                {currentExp}/{infoUser?.max_exp}
              </p>
            </div>
          </div>
          <div className="progress"></div>
        </EarnLevel>
      </EarnRowLevel>
      <PointAppear>
        {pointAppearArray.map((item: any, index: number) => {
          return (
            item.x && (
              <div
                key={index}
                style={{
                  position: "absolute",
                  top: `${item.y}px`,
                  left: `${item.x}px`,
                  transform: "translate(-50%, -50%)",
                  pointerEvents: "none",
                  zIndex: 100,
                }}
              >
                <p>
                  <span>+</span>
                  {item.text}
                </p>
              </div>
            )
          );
        })}
      </PointAppear>
      <WrapClick>
        <div className="img-coin">
          <motion.img
            whileTap={{ scale: 0.9, rotate: -5 }}
            onClick={(e) => {
              handleClickCoin(e);
            }}
            onTouchStart={(e) => {
              handleClickCoin(e);
            }}
            src={"/assets/images/earn/img-coin.png"}
            alt="coin"
          />
        </div>
      </WrapClick>
      <TimeCoundown className="bg-gray row-center">
        <div className="img-time">
          <img src="/assets/images/earn/img-time.png" alt="time" />
        </div>
        <h1>
          {hours && hours < 10 ? `0${hours}` : hours ? `${hours}` : "00"}:
          {minutes && minutes < 10
            ? `0${minutes}`
            : minutes
            ? `${minutes}`
            : "00"}
          :
          {seconds && seconds < 10
            ? `0${seconds}`
            : seconds
            ? `${seconds}`
            : "00"}
        </h1>
      </TimeCoundown>
    </EarnContainer>
  );
}
