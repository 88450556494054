import styled from "styled-components";
import { Banner, ListParent, ShowList } from "../Friends/styled";

export const TaskContainer = styled.div`
  position: relative;
  width: 100%;
  ${Banner} {
    .img-cake {
      max-width: 180px;
      position: absolute;
      right: 0;
    }
  }
  ${ListParent} {
    margin-bottom: 20px;
    height: calc(100vh - 400px);
    height: calc(100svh - 400px);
  }
`;

export const SelectType = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  .active {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const ItemType = styled.div`
  display: flex;
  padding: 14px 0px;
  flex-direction: column;
  align-items: center;
  gap: 73px;
  flex: 1 0 0;
  border-radius: 16px;
`;

export const ItemTask = styled.div`
  width: 100%;
  border-radius: 14px;
  background: rgba(0, 0, 0, 0.25);
  padding-left: 6px;
  h1 {
    color: #fff;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }
  .img-claimed {
    max-width: 80px;
  }
  .claim {
    border-radius: 12px;
    border: 1px solid #bbf7c6;
    background: linear-gradient(180deg, #9779f8 0%, #4800ef 100%);
    box-shadow: 0px 5.235px 10.034px 0px rgba(255, 255, 255, 0.3) inset,
      0px -5.235px 10.034px 0px rgba(255, 255, 255, 0.3) inset;
  }
  .claiming {
    border-radius: 12px;
    border: 1px solid #bbf7c6;
    opacity: 0.5;
    background: linear-gradient(180deg, #9779f8 0%, #4800ef 100%);
    box-shadow: 0px 5.235px 10.034px 0px rgba(255, 255, 255, 0.3) inset,
      0px -5.235px 10.034px 0px rgba(255, 255, 255, 0.3) inset;
  }
`;

export const NameTask = styled.div`
  h1 {
    width: 120px;
    font-size: 12px;
    line-height: 14px;
  }
  .img-task {
    width: 50px;
    margin-right: 5px;
  }
`;

export const ProcessTask = styled.div<{ percent?: string }>`
  width: 100px;
  height: 15px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #43342b;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0.25);
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  p {
    color: #fff;
    text-align: center;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 900;
    line-height: 12px; /* 109.091% */
    z-index: 3;
  }
  &::after {
    position: absolute;
    z-index: 2;
    content: "";
    transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: ${({ percent }) => (percent ? percent : "0%")};
    height: 100%;
    border-radius: 50px;
    background: linear-gradient(275deg, #01c3ff 18.6%, #9e64ff 97.42%),
      linear-gradient(275deg, #bc71ff 18.6%, #8942ff 97.42%);
    left: 0;
  }
`;

export const Reward = styled.div`
  flex-direction: column;
  padding: 6px;
  .img-coin {
    max-width: 30px;
    margin-bottom: 5px;
  }
  p {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 900;
    line-height: 10px; /* 100% */
  }
`;

export const ClaimReward = styled.div`
  margin: 6px;
  border-radius: 12px;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #79d2f8 0%, #00a7ef 100%),
    linear-gradient(180deg, #b5abd7 0%, #2b2b2b 100%);
  box-shadow: 0px 5.235px 10.034px 0px rgba(255, 255, 255, 0.3) inset,
    0px -5.235px 10.034px 0px rgba(255, 255, 255, 0.3) inset;
  padding: 6px;
  width: 80px;
  .img-reward {
    max-width: 25px;
    margin-right: 5px;
  }
  .rotage-img {
    animation: rotateAround 5s infinite linear;
  }
  p {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 153.846% */
  }
`;
