import styled from "styled-components";
import { NameTask, ProcessTask } from "../Tasks/styled";
import { ButtonInvite, ListBtns } from "../Friends/styled";

export const VerifyAccountContainer = styled.div`
  position: relative;
  margin-top: -30px;
  ${ListBtns} {
    margin-top: 30px;
  }
  ${ButtonInvite} {
    width: 100%;
    border-radius: 20px;
    background: linear-gradient(180deg, #48c8ff 0%, #0068ef 100%),
      linear-gradient(180deg, #b5abd7 0%, #2b2b2b 100%);
    box-shadow: 0px 5.235px 10.034px 0px rgba(255, 255, 255, 0.4) inset,
      0px -5.235px 10.034px 0px rgba(255, 255, 255, 0.4) inset;
  }
`;

export const TitleVerify = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  .img-verify {
    max-width: 180px;
  }
  h1 {
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 143.75% */
    background: -webkit-linear-gradient(180deg, #01c3ff, #9f66ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const ListStatus = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-top: 30px;
`;

export const ItemStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 14px;
  padding: 6px 18px;
  background: rgba(255, 255, 255, 0.15);
  ${ProcessTask} {
    width: 250px;
  }
  ${NameTask} {
    h1 {
      margin-bottom: 3px;
      color: #fff;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 166.667% */
    }
  }
  .img-check {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.25);
  }
`;
