import { useSelector } from "react-redux";
import Nodata from "../../components/Nodata";
import {
  AvtAndName,
  Banner,
  ItemFriend,
  ListContainer,
  ListParent,
  ShowList,
  WrapListContainer,
} from "../Friends/styled";
import {
  FooterLine,
  RowBanner,
  RowUserTop,
  TopContainer,
  UserTop,
} from "./styled";
import { useDispatch } from "../../store/store";
import { getDataLeaderBoard } from "../../store/features/rootSlice";
import { useEffect, useState } from "react";
import { convertBigNumberToText } from "../../utils/convertBigNumber";
import { convertFormatNumber } from "../../utils/convertFormatNumber";

export default function TopsPage() {
  const { leaderBoard } = useSelector((state: any) => state.rootStore);
  const dispatch = useDispatch();

  return (
    <TopContainer className="animate__animated animate__fadeIn animate__faster">
      <Banner>
        <div className="img-cake row-center">
          <img src="/assets/images/top/img-cake.png" alt="img" />
        </div>
        <h1>Leaderboard</h1>
        <RowBanner className="row-between">
          <div className="row-left">
            <div className="img-cup row-center">
              <img src="/assets/images/top/img-cup.png" alt="" />
            </div>
            <div>
              <h2>{leaderBoard?.data[0]?.fullname || "--.--"}</h2>
              <p>
                Lv.{" "}
                {leaderBoard?.data[0]?.level_info &&
                leaderBoard?.data[0]?.level_info?.level < 10
                  ? `0${leaderBoard?.data[0]?.level_info?.level}`
                  : "--.--"}
              </p>
            </div>
          </div>
          <div className="bg-dark row-right">
            <div className="img-coin row-center">
              <img src="/assets/images/earn/img-star.png" alt="img" />
            </div>
            <h2>
              EXP:{" "}
              {leaderBoard?.data[0]?.exp
                ? leaderBoard?.data[0]?.exp > 1000000
                  ? convertBigNumberToText(Number(leaderBoard?.data[0]?.exp))
                      ?.toString()
                      .replaceAll(".00", "")
                  : convertFormatNumber(
                      Number(leaderBoard?.data[0]?.exp)
                    )?.replaceAll(".00", "")
                : "--.--"}
            </h2>
          </div>
        </RowBanner>
        <RowUserTop className="row-between">
          <UserTop>
            <div className="row-center">
              <div className="row-center">
                <div className="img-rank row-center">
                  <img src="/assets/images/top/img-rank2.png" alt="rank" />
                </div>
              </div>
              <div>
                <h2>EXP: {leaderBoard?.data[1]?.fullname || "--.--"}</h2>
                <p>
                  Lv.{" "}
                  {leaderBoard?.data[1]?.level_info &&
                  leaderBoard?.data[1]?.level_info?.level < 10
                    ? `0${leaderBoard?.data[1]?.level_info?.level}`
                    : "01"}
                </p>
              </div>
            </div>
            <div className="bg-dark row-right">
              <div className="img-coin row-center">
                <img src="/assets/images/earn/img-star.png" alt="img" />
              </div>
              <h2>
                EXP:
                {leaderBoard?.data[1]?.exp
                  ? leaderBoard?.data[1]?.exp > 1000000
                    ? convertBigNumberToText(Number(leaderBoard?.data[1]?.exp))
                        ?.toString()
                        .replaceAll(".00", "")
                    : convertFormatNumber(
                        Number(leaderBoard?.data[1]?.exp)
                      )?.replaceAll(".00", "")
                  : "--.--"}
              </h2>
            </div>
          </UserTop>
          <UserTop>
            <div className="row-center">
              <div className="row-center">
                <div className="img-rank row-center">
                  <img src="/assets/images/top/img-rank3.png" alt="rank" />
                </div>
              </div>
              <div>
                <h2>{leaderBoard?.data[2]?.fullname || "--.--"}</h2>
                <p>
                  Lv.{" "}
                  {leaderBoard?.data[2]?.level_info &&
                  leaderBoard?.data[2]?.level_info?.level < 10
                    ? `0${leaderBoard?.data[2]?.level_info?.level}`
                    : "01"}
                </p>
              </div>
            </div>
            <div className="bg-dark row-right">
              <div className="img-coin row-center">
                <img src="/assets/images/earn/img-star.png" alt="img" />
              </div>
              <h2>
                EXP:
                {leaderBoard?.data[2]?.exp
                  ? leaderBoard?.data[2]?.exp > 1000000
                    ? convertBigNumberToText(Number(leaderBoard?.data[2]?.exp))
                        ?.toString()
                        .replaceAll(".00", "")
                    : convertFormatNumber(
                        Number(leaderBoard?.data[2]?.exp)
                      )?.replaceAll(".00", "")
                  : "--.--"}
              </h2>
            </div>
          </UserTop>
        </RowUserTop>
      </Banner>
      <ListContainer className="bg-gray">
        <WrapListContainer>
          <ListParent>
            {!leaderBoard || leaderBoard?.total < 4 ? (
              <Nodata top="60px" />
            ) : (
              <ShowList>
                {leaderBoard?.data.length > 0 &&
                  leaderBoard?.data
                    ?.slice(3)
                    .map((item: any, index: number) => (
                      <ItemFriend key={index} className="row-between">
                        <AvtAndName className="row-left">
                          <h4>{index + 4}</h4>
                          <div className="img-avt row-center">
                            <img
                              src="/assets/images/friends/img-avt.png"
                              alt="avt"
                            />
                          </div>
                          <div>
                            <p>{item?.fullname}</p>
                            <h3>
                              Lv.{" "}
                              {item?.level_info && item?.level_info?.level < 10
                                ? `0${item?.level_info?.level}`
                                : "01"}
                            </h3>
                          </div>
                        </AvtAndName>
                        <AvtAndName
                          style={{
                            minWidth: "140px",
                          }}
                          className="row-right"
                        >
                          <div className="img-avt row-center">
                            <img
                              src="/assets/images/earn/img-star.png"
                              alt="avt"
                            />
                          </div>
                          <p>EXP: {item?.exp}</p>
                        </AvtAndName>
                      </ItemFriend>
                    ))}
              </ShowList>
            )}
          </ListParent>
        </WrapListContainer>
        <FooterLine></FooterLine>
      </ListContainer>
    </TopContainer>
  );
}
