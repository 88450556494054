import { Outlet, useNavigate } from "react-router-dom";
import { DashboardContainer, DashboardMain } from "./styled";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ROUTERS } from "../../constants";

const LayoutDashboard = () => {
  const { infoUser } = useSelector((state: any) => state.rootStore);

  useEffect(() => {
    if (!infoUser) {
      window.location.replace(ROUTERS.loading);
    }
  }, [infoUser]);

  return (
    <DashboardContainer>
      {infoUser && (
        <>
          <Header />
          <DashboardMain>
            <Outlet />
          </DashboardMain>
          <Footer />
        </>
      )}
    </DashboardContainer>
  );
};
export default LayoutDashboard;
