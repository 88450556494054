import { AppContainer } from "./styled";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import "./App.css";
import { Navigate, Route, Router, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useContext, useEffect, useMemo } from "react";
import LayoutDashboard from "./Layout/Dashboard";
import { store } from "./store/store";
import { Provider, useSelector } from "react-redux";
import WebApp from "@twa-dev/sdk";
import { ContextProvider, ContextProviderWrapper } from "./components/Context";
import { ROUTERS } from "./constants";
import EarnPage from "./page/Earn";
import FriendsPage from "./page/Friends";
import WalletPage from "./page/Wallet";
import TaskPage from "./page/Tasks";
import TopsPage from "./page/Tops";
import VerifyAccountPage from "./page/Verify";
import RewardPage from "./page/Reward";
import LoadingPage from "./page/Loading";
import RootLayout from "./Layout/rootLayout";
import {
  createNavigator,
  useNavigatorIntegration,
} from "@tma.js/react-router-integration";
import "animate.css";
import eruda from "eruda";
// eruda.init();

const App = () => {
  const tmaNavigator = useMemo(createNavigator, []);
  const [location, navigator] = useNavigatorIntegration(tmaNavigator);

  useEffect(() => {
    WebApp.ready();
  }, []);

  return (
    <Router location={location} navigator={navigator}>
      <ContextProvider>
        <Provider store={store}>
          <TonConnectUIProvider manifestUrl="https://clicker.mbitwallet.com/tonconnect-manifest.json">
            <Toaster position="top-right" reverseOrder={false} />
            <AppContainer>
              <Routes>
                <Route element={<RootLayout />}>
                  <Route path={ROUTERS.loading} element={<LoadingPage />} />
                  <Route
                    path={ROUTERS.verify}
                    element={<VerifyAccountPage />}
                  />
                  <Route path={ROUTERS.reward} element={<RewardPage />} />
                  <Route element={<LayoutDashboard />}>
                    <Route path={ROUTERS.earn} element={<EarnPage />} />
                    <Route path={ROUTERS.top} element={<TopsPage />} />
                    <Route path={ROUTERS.task} element={<TaskPage />} />
                    <Route path={ROUTERS.friends} element={<FriendsPage />} />
                    <Route path={ROUTERS.wallet} element={<WalletPage />} />
                  </Route>
                  <Route path="*" element={<Navigate to={ROUTERS.earn} />} />
                </Route>
              </Routes>
            </AppContainer>
          </TonConnectUIProvider>
        </Provider>
      </ContextProvider>
    </Router>
  );
};

export default App;
