import { useNavigate } from "react-router-dom";
import {
  DashboardContainer,
  DashboardContainerDesKtop,
  DashboardMain,
} from "../../Layout/Dashboard/styled";
import {
  LoadingContainer,
  LoadingLogo,
  TitleLoading,
  WrapLoading,
} from "./styled";
import { useContext, useEffect, useState } from "react";
import WebApp from "@twa-dev/sdk";
import { MBIT_API } from "../../services/api";
import { useDispatch } from "../../store/store";
import {
  getDataInfoUser,
  getDataLeaderBoard,
  getDataListAsset,
  getDataListFriends,
  getDataListTask,
} from "../../store/features/rootSlice";
import { ROUTERS } from "../../constants";
import { useInitData } from "@tma.js/sdk-react";
import { useSelector } from "react-redux";
import { useTonAddress } from "@tonconnect/ui-react";
import { isMobile } from "../../utils/checkingMobile";

export default function LoadingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { infoUser } = useSelector((state: any) => state.rootStore);
  const address = useTonAddress();

  const [isLogin, setIsLogin] = useState(false);

  const dataUserTele: any = useInitData();
  const {
    initData: { user },
  } = dataUserTele;

  const { initDataUnsafe } = WebApp;

  const handleLogin = async () => {
    try {
      if (!isMobile()) return;
      const localTeleId = localStorage.getItem("localTeleId");
      if (localTeleId && localTeleId === `${user.id}`) {
        setIsLogin(true);
      } else {
        const { data } = await MBIT_API.verifyTeleId(`${user.id}`);
        const startParams = initDataUnsafe.start_param;
        const listAddressFromParams: any = startParams?.split("=");
        const payload = {
          key: `${data.key}`,
          telegram_name: `${user.firstName} ${user.lastName}`,
          referral_code:
            listAddressFromParams && listAddressFromParams.length > 0
              ? listAddressFromParams[0]
              : "",
          is_premium: user.isPremium,
        };

        const res: any = await MBIT_API.loginWithTele(payload);
        const { accessToken } = res.data;
        localStorage.setItem("localTeleId", `${user.id}`);
        await localStorage.setItem("token", accessToken);
        const timeCountDown = localStorage.getItem("timeCountDown");
        if (!timeCountDown) {
          localStorage.setItem("timeCountDown", `${new Date()}`);
        }
        setIsLogin(true);
      }
    } catch (error: any) {
      localStorage.setItem("localTeleId", "");
      console.log("handleLogin err", error);
    }
  };

  const handlePrepairData = async () => {
    try {
      await Promise.all([
        dispatch(getDataInfoUser(`${user.id}`)),
        dispatch(getDataListFriends()),
        dispatch(
          getDataLeaderBoard({
            perPage: 203,
          })
        ),
        dispatch(getDataListTask()),
      ]);
      if (address) {
        dispatch(getDataListAsset(address));
      }
    } catch (error) {
      console.log("====================================");
      console.log("handlePrepairData err", error);
      console.log("====================================");
      await localStorage.setItem("localTeleId", "");
      handleLogin();
    }
  };

  const settingDefaultMiniApp = () => {
    WebApp.isClosingConfirmationEnabled = true;
    WebApp.expand();
    WebApp.ready();
    const overflow = 100;
    document.body.style.overflowY = "hidden";
    document.body.style.marginTop = `${overflow}px`;
    document.body.style.height = window.innerHeight + overflow + "px";
    document.body.style.paddingBottom = `${overflow}px`;
    window.scrollTo(0, overflow);
  };

  useEffect(() => {
    settingDefaultMiniApp();
    handleLogin();
  }, []);

  useEffect(() => {
    if (isLogin) {
      handlePrepairData();
    }
  }, [isLogin]);

  useEffect(() => {
    if (!infoUser) return;
    setTimeout(() => {
      if (infoUser.is_verified) {
        navigate(ROUTERS.earn);
      } else {
        navigate(ROUTERS.verify);
      }
    }, 2000);
  }, [infoUser]);

  return (
    <DashboardContainer className="animate__animated animate__fadeIn animate__faster">
      {isMobile() ? (
        <DashboardMain>
          <LoadingContainer>
            <TitleLoading>
              <WrapLoading>
                <LoadingLogo>
                  <div className="img-loading row-center">
                    <img
                      src="/assets/images/common/img-loading.png"
                      alt="loading"
                    />
                  </div>
                  <svg viewBox="0 0 100 100">
                    <path
                      d="M 50 96 a 46 46 0 0 1 0 -92 46 46 0 0 1 0 92"
                      fill="#5bcfff"
                    />
                  </svg>
                </LoadingLogo>
              </WrapLoading>
              <h1>Loading...</h1>
            </TitleLoading>
          </LoadingContainer>
        </DashboardMain>
      ) : (
        <DashboardContainerDesKtop>
          <div className="img-qr-game">
            <img src="/assets/images/common/qr-game-prod.jpg" alt="game" />
          </div>
          <h1>Play on your phone, please!</h1>
        </DashboardContainerDesKtop>
      )}
    </DashboardContainer>
  );
}
