import styled from "styled-components";

export const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 20px;
  padding: 0 16px;
  z-index: 99;
`;

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  .bg-dark {
    padding: 6px;
    flex: 1 0 0;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.15);
  }
  h1 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }
`;

export const InfoUser = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  .img-avt {
    max-width: 40px;
  }
`;

export const InfoPoints = styled.div`
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 18px;
  background: rgba(0, 0, 0, 0.5);
  .img-yl {
    max-width: 30px;
  }
`;
