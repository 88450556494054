import styled from "styled-components";

export const DashboardContainer = styled.div`
  overflow: hidden;
  height: 100svh;
  background-image: url("/assets/images/common/bg-01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .bg-gray {
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.15);
    padding: 6px;
  }
  .bg-dark {
    border-radius: 24px;
    background: rgba(0, 0, 0, 0.5);
    padding: 6px;
  }
  .bg-light {
    background: linear-gradient(180deg, #6a48ff 0%, #0068ef 100%);
    mix-blend-mode: plus-lighter;
    box-shadow: 0px 5.235px 10.034px 0px rgb(255, 255, 255, 0.3) inset,
      0px -5.235px 10.034px 0px rgb(255, 255, 255, 0.3) inset;
  }
  .bg-light-color {
    background: linear-gradient(275deg, #01c3ff 18.6%, #9f66ff 97.42%),
      linear-gradient(275deg, #bc71ff 18.6%, #8942ff 97.42%);
  }
  .row-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .row-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .row-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  p {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }
  @keyframes upAndDown {
    0% {
      transform: translateY(-30px);
    }
    25% {
      transform: translateY(-15px);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(-30px);
    }
  }
  @keyframes rotateAround {
    0% {
      transform: rotateZ(0deg);
    }
    25% {
      transform: rotateZ(180deg);
    }
    50% {
      transform: rotateZ(360deg);
    }
    75% {
      transform: rotateZ(540deg);
    }
    100% {
      transform: rotateZ(720deg);
    }
  }
`;

export const DashboardMain = styled.div`
  max-width: 450px;
  margin: 0 auto;
  height: 100svh;
  position: relative;
  padding: 0 16px;
  padding-top: 85px;
  padding-bottom: 110px;
`;

export const DashboardContainerDesKtop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  height: 100svh;
  .img-qr-game {
    max-width: 100%;
    margin: 0 auto;
    img {
      width: 100%;
      margin-top: -130px;
    }
  }
  h1 {
    color: #fff !important;
    z-index: 2;
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 21px; /* 87.5% */
    margin-top: -280px;
  }
`;
