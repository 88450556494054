import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { ROUTERS } from "../constants";

export default function RootLayout() {
  const { infoUser } = useSelector((state: any) => state.rootStore);
  const navigate = useNavigate();
  useEffect(() => {
    if (!infoUser) {
      navigate(ROUTERS.loading);
    }
  }, []);
  return (
    <div>
      <Outlet />
    </div>
  );
}
