import { Dispatch, createSlice } from "@reduxjs/toolkit";
import { MBIT_API } from "../../services/api";
import { getTokenInWallet } from "../../utils/getTokenInWallet";
import _ from "lodash";

export interface RootState {
  infoUser: string | null;
  currentPoint: number;
  listFriends: any;
  leaderBoard: any;
  listTask: any;
  listAsset: any;
}

const initialState: RootState = {
  infoUser: null,
  currentPoint: 0,
  listFriends: null,
  leaderBoard: {
    data: [],
    total: 0,
  },
  listTask: null,
  listAsset: null,
};

export const rootSlice = createSlice({
  name: "rootSlice",
  initialState,
  reducers: {
    getInfoUserSuccess(state, action) {
      state.infoUser = action.payload;
    },
    setDataPointSuccess(state, action) {
      state.currentPoint = action.payload;
    },
    getListFriendsSuccess(state, action) {
      const { data, total, isLoadMore } = action.payload;
      const dataConvert = {
        data: isLoadMore ? state.listFriends?.data?.concat(data) : data,
        total,
      };
      state.listFriends = dataConvert;
    },
    getLeaderBoadrdSuccess(state, action) {
      const { data, total, isLoadMore } = action.payload;

      const topUsers = isLoadMore
        ? state.leaderBoard?.data?.concat(data)?.splice(0, 200)
        : data;

      const dataConvert = {
        data: _.sortBy(topUsers, ["level_info.level", "exp"])?.reverse(),
        total,
      };
      state.leaderBoard = dataConvert;
    },
    getListTaskSuccess(state, action) {
      state.listTask = action.payload;
    },
    getListAssetSuccess(state, action) {
      state.listAsset = action.payload;
    },
  },
});

export function getDataInfoUser(telegramId?: string) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await MBIT_API.getInfoUser();
      dispatch(rootSlice.actions.getInfoUserSuccess(data));
    } catch (error) {
      console.log(error);
      localStorage.setItem("localTeleId", "");
    }
  };
}

export function setDataPoint(points: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(rootSlice.actions.setDataPointSuccess(points));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataListFriends(params?: any) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await MBIT_API.getListFriends(params);
      dispatch(
        rootSlice.actions.getListFriendsSuccess({
          ...data,
          isLoadMore: params !== undefined,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataLeaderBoard(params?: any) {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await MBIT_API.getLeaderBoard(params);
      dispatch(
        rootSlice.actions.getLeaderBoadrdSuccess({
          ...data,
          isLoadMore: params !== undefined,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataListTask() {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await MBIT_API.getListTask();
      dispatch(rootSlice.actions.getListTaskSuccess(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getDataListAsset(address: string) {
  return async (dispatch: Dispatch) => {
    try {
      if (!address) return;
      const listTokenInWallet = await getTokenInWallet(address);
      dispatch(rootSlice.actions.getListAssetSuccess(listTokenInWallet));
    } catch (error) {
      console.log(error);
    }
  };
}

const rootReducer = rootSlice.reducer;

export default rootReducer;
