import styled from "styled-components";

export const EarnContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const EarnRowLevel = styled.div`
  position: relative;
  width: 100%;
`;

export const EarnLevel = styled.div<{ percent: number }>`
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-image: linear-gradient(180deg, #0a0b0a 0%, #302520 100%);
  z-index: 2;
  left: 37%;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  .around-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
    background: #302f42;
    transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .around-3 {
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 87px;
    height: 87px;
    border-radius: 50%;
    margin: auto;
    transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background: linear-gradient(180deg, #48c8ff -17.94%, #0068ef 76.18%);
    box-shadow: 0px 13px 10.034px 0px rgba(255, 255, 255, 0.2) inset,
      0px -13px 10.034px 0px rgba(255, 255, 255, 0.2) inset;
    h1 {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 100% */
      margin-bottom: 5px;
    }
    p {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 10px; /* 100% */
    }
    .bg-dark {
      border-radius: 18px;
      background: rgba(0, 0, 0, 0.5);
      display: inline-flex;
      padding: 4px;
      align-items: center;
      gap: 4px;
    }
    .img-star {
      max-width: 12px;
    }
  }
  .progress {
    position: absolute;
    width: 100px;
    height: 100px;
    margin: auto;
    background: #5bcfff;
    border-radius: 50%;
    z-index: 1;
    transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background: ${({ percent }) =>
      percent
        ? `conic-gradient(#d4f2ff 0% ${percent}%, 
          transparent ${percent + 1}% 100%)`
        : ""};
  }
`;

export const CountClick = styled.div`
  .img-lightning {
    max-width: 42px;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
  &:nth-child(2) {
    .img-boost {
      position: relative;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 42px;
      height: 42px;
      img {
        position: absolute;
        top: 3px;
      }
    }
  }
`;

export const WrapClick = styled.div`
  position: relative;
  margin-top: calc(100vh / 2 - 280px);
  .img-coin {
    max-width: 270px;
    margin: 0 auto;
    -webkit-tap-highlight-color: transparent;
  }
`;

export const PointAppear = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  p {
    opacity: 0;
    animation: flowPoint 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    color: #fff;
    text-align: center;
    text-shadow: 2px 2px 0px #341838;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px; /* 55.556% */
  }
  &.is-click {
    pointer-events: auto;
  }
  @keyframes flowPoint {
    0% {
      transform: -100px;
    }
    10% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    70% {
      opacity: 0.4;
    }
    100% {
      transform: translateY(-300%);
    }
  }
`;

export const TimeCoundown = styled.div`
  position: absolute;
  width: 100%;
  bottom: 5px;
  .img-time {
    max-width: 40px;
    margin-right: 6px;
  }
  h1 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
  }
`;
