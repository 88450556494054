import { NavLink } from "react-router-dom";
import { FooterContainer, FooterWrap, NavItem } from "./styled";
import { ROUTERS } from "../../constants";

export default function Footer() {
  return (
    <FooterContainer>
      <FooterWrap>
        {LINKS.map((item: any, index: number) => (
          <NavLink key={index} to={item.link}>
            <NavItem>
              <div className="img-nav">
                <img src={item.icon} alt="img" />
              </div>
              <p>{item.label}</p>
            </NavItem>
          </NavLink>
        ))}
      </FooterWrap>
    </FooterContainer>
  );
}

const LINKS = [
  {
    label: "Friends",
    icon: "/assets/images/header/icon-invite.png",
    link: ROUTERS.friends,
  },
  {
    label: "Task",
    icon: "/assets/images/header/icon-docs.png",
    link: ROUTERS.task,
  },
  {
    label: "Earn",
    icon: "/assets/images/header/icon-rocket.png",
    link: ROUTERS.earn,
  },
  {
    label: "Top",
    icon: "/assets/images/header/icon-top.png",
    link: ROUTERS.top,
  },
  {
    label: "Wallet",
    icon: "/assets/images/header/icon-wallet.png",
    link: ROUTERS.wallet,
  },
];
