import { NodataContainer } from "./styled";

type NodataType = {
  des?: string;
  img?: string;
  top?: string;
};

export default function Nodata({ des, img, top }: NodataType) {
  return (
    <NodataContainer
      style={{
        marginTop: top ? top : "20px",
      }}
    >
      <div className="img-nodata row-center">
        <img
          src={img ? img : "/assets/images/common/img-nodata.png"}
          alt="nodata"
        />
      </div>
      <p>{des ? des : "You don’t have data"}</p>
    </NodataContainer>
  );
}
