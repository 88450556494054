import styled from "styled-components";
import { EarnLevel } from "../Earn/styled";

export const LoadingContainer = styled.div`
  position: relative;
`;

export const TitleLoading = styled.div`
  h1 {
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 143.75% */
    background: -webkit-linear-gradient(180deg, #01c3ff, #9f66ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: auto;
    margin-bottom: 20px;
    position: absolute;
    width: 100%;
    top: 100px;
  }
`;

export const WrapLoading = styled.div`
  position: relative;
  margin-top: 190px;
  ${EarnLevel} {
    background: transparent;
    .around-2 {
      background: rgba(0, 0, 0, 0.3);
    }
    .around-3 {
      background: transparent;
    }
  }
`;

export const LoadingLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  .img-loading {
    max-width: 85px;
    padding: 8px;
    animation: rotateAround 5s infinite linear;
  }
  svg {
    max-width: 90px;
    position: absolute;
  }

  path {
    stroke: #8fdaff;
    stroke-width: 8;
    stroke-dasharray: 288.5;
    stroke-linecap: round;
    fill: none;
    transform-origin: 50px 50px;
    animation: pathStroke 3.75s infinite ease-in-out,
      pathRotate 7.5s infinite cubic-bezier(1, 0.5, 0, 0.5);
  }

  @keyframes pathStroke {
    0% {
      stroke-dashoffset: -288.5;
    }
    40%,
    70% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 288.5;
    }
  }

  @keyframes pathRotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-540deg);
    }
    100% {
      transform: rotate(-1080deg);
    }
  }
`;
