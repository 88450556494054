export const ROUTERS = {
  earn: "/earn",
  friends: "/friends",
  top: "/top",
  task: "/tasks",
  wallet: "wallet",
  verify: "/verify",
  reward: "/reward",
  loading: "/",
};
