import { useSelector } from "react-redux";
import Nodata from "../../components/Nodata";
import {
  Banner,
  CountFriend,
  FriendsContainer,
  InfoReward,
  TitleList,
  TypeInvite,
  ListContainer,
  WrapListContainer,
  ListParent,
  ItemFriend,
  AvtAndName,
  ListBtns,
  ButtonInvite,
  ShowList,
} from "./styled";
import { useEffect, useState } from "react";
import { getDataListFriends } from "../../store/features/rootSlice";
import { useDispatch } from "../../store/store";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import { useInitData } from "@tma.js/sdk-react";
import WebApp from "@twa-dev/sdk";

export default function FriendsPage() {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { listFriends } = useSelector((state: any) => state.rootStore);
  const dispatch = useDispatch();
  const dataUserTele: any = useInitData();
  const {
    initData: { user },
  } = dataUserTele;

  const handleLoadMore = (e: any) => {
    try {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      const position = Math.ceil(
        (scrollTop / (scrollHeight - clientHeight)) * 100
      );
      if (position > 80 && listFriends?.data?.length < listFriends?.total) {
        dispatch(
          getDataListFriends({
            page: currentPage + 1,
          })
        );
        setCurrentPage(currentPage + 1);
        e.target.clientHeight = 0;
      }
    } catch (error) {
      // console.log('====================================');
      // console.log('handleLoadMore err', error);
      // console.log('====================================');
    }
  };

  const handleInviteFriend = (type: string) => {
    try {
      const link = `${process.env.REACT_APP_BOT_LINK}?startapp=${user.id}`;
      const shareLink = `https://t.me/share/url?url=${encodeURIComponent(
        link
      )}`;
      if (type === "copy") {
        navigator.clipboard.writeText(link);
        toast.success("Copied !!");
      } else {
        WebApp.openTelegramLink(shareLink);
      }
    } catch (error) {
      console.log("====================================");
      console.log("handleInviteFriend err", error);
      console.log("====================================");
    }
  };

  useEffect(() => {
    window.addEventListener("onscroll", handleLoadMore);
    return () => window.removeEventListener("onscroll", handleLoadMore);
  }, []);

  return (
    <FriendsContainer className="animate__animated animate__fadeIn animate__faster">
      <Banner>
        <div className="img-heart">
          <img src="/assets/images/friends/img-heart.png" alt="heart" />
        </div>
        <div className="img-like">
          <img src="/assets/images/friends/img-like.png" alt="like" />
        </div>
        <h1>
          Invite Friend <br />
          Now!
        </h1>
        <p>
          Invite your friend to play the game together and reserve many rewards.
        </p>
        <InfoReward className="row-between">
          <div className="bg-dark row-left">
            <div className="img-member">
              <img src="/assets/images/friends/img-star.png" alt="star" />
            </div>
            <TypeInvite>
              <h1>Invite Member</h1>
              <p>+100 MBIT</p>
            </TypeInvite>
          </div>
          <div className="bg-dark row-left">
            <div className="img-member">
              <img src="/assets/images/friends/img-eart.png" alt="star" />
            </div>
            <TypeInvite>
              <h1>Invite Premium</h1>
              <p>+150 MBIT</p>
            </TypeInvite>
          </div>
        </InfoReward>
      </Banner>
      <ListContainer className="bg-gray">
        <WrapListContainer>
          <TitleList className="row-between">
            <h1>My Referals List</h1>
            <CountFriend className="row-center bg-gray">
              <div className="img-user">
                <img src="/assets/images/friends/icon-user.png" alt="user" />
              </div>
              <p>My ref: {listFriends?.total}</p>
            </CountFriend>
          </TitleList>
          <ListParent onScroll={handleLoadMore}>
            {!listFriends || listFriends?.total === 0 ? (
              <Nodata top="60px" />
            ) : (
              <ShowList>
                {listFriends?.data?.map((item: any, index: number) => (
                  <ItemFriend key={index} className="row-between">
                    <AvtAndName className="row-left">
                      <div className="img-avt row-center">
                        <img
                          src="/assets/images/friends/img-avt.png"
                          alt="avt"
                        />
                      </div>
                      <p>{item.username}</p>
                    </AvtAndName>
                    <AvtAndName className="row-right">
                      <div className="img-avt row-center">
                        <img src="/assets/images/earn/img-star.png" alt="avt" />
                      </div>
                      <p>
                        Lv.
                        {item?.level_info && item?.level_info?.level < 10
                          ? `0${item?.level_info?.level}`
                          : "01"}
                      </p>
                    </AvtAndName>
                  </ItemFriend>
                ))}
              </ShowList>
            )}
          </ListParent>
        </WrapListContainer>
        <ListBtns className="row-between">
          <ButtonInvite
            className="row-center"
            onClick={() => {
              handleInviteFriend("copy");
            }}
          >
            <div className="img-btn row-center">
              <img src="/assets/images/friends/icon-copy.png" alt="btn" />
            </div>
            <p>Copy Link</p>
          </ButtonInvite>
          <ButtonInvite
            className="row-center"
            onClick={() => {
              handleInviteFriend("share");
            }}
          >
            <div className="img-btn row-center">
              <img src="/assets/images/friends/icon-share.png" alt="btn" />
            </div>
            <p>Share Link</p>
          </ButtonInvite>
        </ListBtns>
      </ListContainer>
    </FriendsContainer>
  );
}
