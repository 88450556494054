import { instance } from "../instance";
import { LoginWithTeleType, SendClickCountType } from "./apiType";

export const MBIT_API = {
  verifyTeleId(telegram_id: string) {
    return instance.post("/auth/verify-telegramId", { telegram_id });
  },
  loginWithTele(payload: LoginWithTeleType) {
    return instance.post("/auth/connect-telegram", payload);
  },
  getInfoUser() {
    return instance.get(`/users/profile`);
  },
  verifyAccount() {
    return instance.post("/auth/verify-account");
  },
  sendClickCount(payload: SendClickCountType) {
    return instance.post("/points/click", payload);
  },
  getListFriends(param?: any) {
    return instance.get(
      `/users/friends?pagination[page]=${
        param ? param.page : 1
      }&pagination[perPage]=10`
    );
  },
  getLeaderBoard(params: any) {
    return instance.get(
      `/points/leaderboard?pagination[page]=${
        params ? params.page : 1
      }&pagination[perPage]=${params ? params.perPage : 10}`
    );
  },
  getListTask() {
    return instance.get(
      `/tasks/list?pagination[page]=1&pagination[perPage]=50&sort[field]=created_at&sort[order]=DESC`
    );
  },
  excuteTask(task_id: any) {
    return instance.post(`/tasks/excute-task`, task_id);
  },
  connectWallet(address: string) {
    return instance.post(`/users/connect-wallet`, { address });
  },
  updateTask(params: string) {
    return instance.post(`/tasks/update`, params);
  },
};
