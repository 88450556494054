import styled from "styled-components";

export const NodataContainer = styled.div`
  position: relative;
  p {
    color: rgba(255, 255, 255, 0.5);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px; /* 78.571% */
  }
  .img-nodata {
    max-width: 60px;
    margin: 0 auto;
  }
`;
